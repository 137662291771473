import * as React from "react";

// Hero Media

export const HeroMedia = () => {
  return (
    <>
      <img 
        className="media bkg d-none d-sm-block" 
        src="/images/how-to-get-hiv-tested/get-tested-hero.png" 
        alt="Two men lying in the grass looking at each other" 
      />
      <img 
        className="media bkg d-block d-sm-none" 
        src="/images/how-to-get-hiv-tested/get-tested-hero-mobile.png" 
        alt="Two men lying in the grass looking at each other" 
      />
    </>
  )
}

// Hero Call To Action

export const Cta = (props) => {
  return (
    <>
      <h1>
        Taking an HIV Test: What to Expect
      </h1>
      <p>
        It’s natural to feel nervous, scared, or overwhelmed about getting tested for HIV. Just 
        remember there is confidence in knowing your HIV status, no matter the test&nbsp;results.  
      </p>
      <div 
        onClick={() => {props.scrollToElement("nav-anchor-where-to-get-tested",-70,-370)}}
        className="scroll" role="button">
        <p className="copy">
          SCROLL
        </p>
        <img className="icon-scroll" src="/images/global/play-icon.svg" />
      </div>
    </>
  )
} 
