import * as React from 'react';

const DifferentTypesofTests = () => {
  return (
    <>
      <section id="nav-anchor-where-to-get-tested" className="container-fluid different-types-of-tests bkg-lighter-gray">
        <div className="container px-0">
          <div className="row">
            <div className="col">
              <h2>
                Different Types of Tests
              </h2>
              <p className="summary">
                There are options when it comes to types of HIV tests. Use the information below to help
                figure out which test works for you. Keep in mind, no matter which type
                you take, you're doing the right thing by getting&nbsp;tested.
              </p>
              <div className="chart">
                <ul className="left">
                  <li>
                    Mouth swab
                  </li>
                  <li>
                    Finger prick
                  </li>
                  <li>
                    Blood draw
                  </li>
                </ul>
                <div className="right">
                  <p>
                    Some types have a rapid option
                  </p>
                  <p>
                    Tests may be confidential or anonymous
                  </p>
                  <p>
                    Many tests are free<sup>†</sup>
                  </p>
                </div>
              </div>
              <p className="footnote">
                <span>†</span>HIV tests are covered by many health insurance plans. There
                are free or low-cost testing options for those without insurance.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}

export default DifferentTypesofTests;