import * as React from 'react';
import Bar from '../global/Bar.js';

const WhereToGetTested = () => {
  return (
    <>
      <section id="nav-anchor-where-to-get-tested" className="container-fluid where-to-get-tested bkg-white">
        <div className="container px-0">
          <div className="row">
            <div className="col">
              <h2>
                Where to Get <br className="d-block d-md-none" />Tested
              </h2>
              <p className="summary">
                The cost of HIV testing is covered by many health insurance plans. If
                you aren't insured, there are free HIV tests available.
              </p>
            </div>
          </div>
          <div className="row callouts">
            <div className="col-12 col-lg-6 container-icon location">
              <div className="content">
                <img
                  className="icon d-none d-md-block"
                  src='/images/how-to-get-hiv-tested/location-icon-orange.svg'
                  alt="Find an HIV Testing Location"
                />
                <img
                  className="icon d-block d-md-none"
                  src="/images/how-to-get-hiv-tested/location-icon-orange-mobile.svg"
                  alt="Find an HIV Testing Location"
                />
                <h3>
                  Testing sites near you
                </h3>
                <p className="description first">
                  Find an HIV testing location near you. The testing site you choose will most likely either 
                  use a blood or oral sample to test. Tests may be confidential or anonymous and they are often free.
                </p>
              </div>
              <a
                target="_blank"
                data-interstitial-type="aidsvu"
                className="button blue opens-interstitial"
                href="https://aidsvu.org/services/#/testing">
                FIND AN HIV TESTING LOCATION
              </a>
            </div>
            <div className="col-12 col-lg-6 container-icon home">
              <div className="content">
                <img
                  className="icon d-none d-md-block"
                  src='/images/how-to-get-hiv-tested/buildings.svg'
                  alt="Test at Home" />
                <img
                  className="d-block d-md-none icon d-block d-md-none"
                  src="/images/how-to-get-hiv-tested/buildings.svg"
                  alt="Test at Home"
                />
                <h3>
                  Free at-home tests
                </h3>
                <p className="description second">
                  Order up to 2 fast, free, and discreet oral self-tests every 90 days. Keep in 
                  mind a healthcare professional should perform follow-up tests to confirm your results.
                </p>
              </div>
              <a 
                target="_blank"
                className="button blue long opens-interstitial" 
                data-interstitial-type="gilead"
                href="https://together.takemehome.org/?utm_source=Gilead&utm_medium=Partner-Organic-English&utm_campaign=selftesting12_PRIORITY&utm_term=BHOC-no-campaign-term&utm_content=Partner-promotion">
                ORDER AT-HOME TESTS*
              </a>
              <p className="footnote-eligibility">
                *Eligibility requirements may apply.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )

}

export default WhereToGetTested;