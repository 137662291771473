import * as React from "react"
import '../assets/sass/app.scss';
import Layout from '../components/global/Layout.js';
import { HeroMedia, Cta } from '../components/how-to-get-hiv-tested/HeroContent.js'
import WhereToGetTested from "../components/how-to-get-hiv-tested/WhereToGetTested";
import DifferentTypesofTests from "../components/how-to-get-hiv-tested/DifferentTypesofTests";
import FooterCalloutBar from "../components/global/FooterCalloutBar.js";
import Header from '../components/global/Header.js';
import scrollToElement from '../components/global/ScrollToElement';

const HowToGetHIVTested = () => {
  return (
    <>
      <Header page="how-to-get-hiv-tested" scrollToElement={scrollToElement} />
      <Layout
        page="how-to-get-hiv-tested"
        heroMedia={<HeroMedia />}
        CTA={Cta}
      >
        <WhereToGetTested />
        <DifferentTypesofTests />
        <FooterCalloutBar/>
      </Layout>
    </>
  )
}

export default HowToGetHIVTested
